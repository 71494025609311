<template>
  <div class="view-container">
    <div class="card display--flex">
      <div class="group-form__organization">
        <div>
          <p v-if="profile && profile.company" class="group-form__text">
            {{ profile.company.name }}
          </p>
        </div>
      </div>
      <div class="group-form__group">
        <div class="card-header card-header-divider">
          <div class="card-title">Группа</div>
          <div class="tools">
            <button
              type="button"
              @click="createNewStaff"
              class="btn btn-accent btn-sm"
            >
              Создать сотрудника
            </button>
          </div>
        </div>
        <div v-if="!isPreloaderLoad" class="card-body">
          <form action="#" @submit.prevent="submitGroup" novalidate>
            <div
              class="group-form__inputs display--flex justify-content-center"
            >
              <div class="group-form__col1">
                <custom-input
                  label="Название"
                  req
                  name="name"
                  v-model="form.name"
                />
                <custom-select-2
                  v-if="profile.role !== 'curator'"
                  label="Куратор"
                  id="curatorSelect"
                  name="owner_id"
                  v-model="form.owner_id"
                  :options="curatorsData"
                />
                <custom-select-2
                  label="Участники"
                  name="users"
                  id="usersSelect"
                  :multiple="true"
                  v-model="form.users"
                  :settings="usersAjax"
                >
                </custom-select-2>

                <div
                  v-if="route.params.id"
                  class="group-form__children-area children-area"
                >
                  <p>Подгруппы</p>
                  <div
                    class="
                          children-area__header
                          display--flex
                          justify-content-around
                          align-item-center
                        ">
                    <div class="children-area__input">
                      <custom-input
                        name="childName"
                        :inputWidth="100"
                        placeholder="Имя новой подгруппы"
                        v-model="childGroupForm.name"
                      />
                    </div>
                    <button
                      type="button"
                      @click="newChildGroup"
                      class="btn btn-w"
                    >
                      Создать
                    </button>
                  </div>
                  <div class="children-area__list" v-if="childs">
                    <div
                      v-for="child in childs"
                      :key="child.id"
                      class="children-area__item"
                    >
                      <router-link :to="`/group/edit/${child.id}`"
                        >{{ child.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                    group-form__btns
                    mt-25
                    mb-10
                    display--flex
                    justify-content-end
              "
            >
              <button type="submit" class="btn btn-accent mr-10">
                Сохранить
              </button>
              <button type="button" @click="router.go(-1)" class="btn btn-w">
                Назад
              </button>
            </div>
            <div v-if="isPreloaderSave" class="form-preloader">
              <preloader />
            </div>
          </form>
        </div>
        <div v-else class="table-preloader">
          <preloader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { computed, onMounted, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import standartError from "../../../mixins/standartError";
import Preloader from "@/components/Technical/Preloader.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import $ from "jquery";

export default {
  components: { CustomSelect2, CustomInput, Preloader },
  name: "group-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter(),
      isPreloaderLoad = ref(false),
      isPreloaderSave = ref(false),
      { setErrors, clearCustomErrors } = useErrors();
    const form = reactive({
      name: "",
      owner_id: null,
      users: null,
      parent: null,
    }),
      childGroupForm = {
        name: null,
        users: null,
      };
    const group = computed(() => store.state.group.group.group),
      childs = computed(() => store.state.group.group.childs),
      staffs = computed(() => store.state.users.users),
      profile = computed(() => store.state.profile.profile),
      newStaffForGroup = computed(() => store.state.group.newStaffForGroup),
      newStaffId = computed(() => store.state.users.userId),
      curators = computed(() => store.state.curator.curators);
    let curatorsData = ref([])
    const childUsersOptions = ref([]),
      newChild = ref("");
    const { usersAjax } = useAjaxSelect();

    curatorsData = computed(() => {
      return curators.value.map((curator) => {
        return {
          text: `${curator.name} (${curator.email})`,
          id: curator.id,
        }
      })
    })

    onMounted(() => {
      store.dispatch("curator/getCurators")

      if (route.params.id) {
        isPreloaderLoad.value = true;
        store.dispatch("group/getGroup", route.params.id).then(() => {
          form.name = group.value.name;
          form.parent = group.value.parent;
          addPrevInfo();
          childUsersOptions.value = group.value.users.map((user) => {
            return { id: user.id, text: user.name };
          });
          isPreloaderLoad.value = false;
          if (newStaffForGroup.value) {
            store.commit("group/setNewStaffForGroup", false);
            if (newStaffId.value) {
              isPreloaderSave.value = true;
              form.users
                ? form.users.push(Number(newStaffId.value))
                : (form.users = [Number(newStaffId.value)]);
              store
                .dispatch("group/updateGroupUsers", {
                  id: route.params.id,
                  users: form.users.map((user) => Number(user)),
                })
                .then(() => (isPreloaderSave.value = false))
                .catch(
                  standartError(setErrors, clearCustomErrors, isPreloaderSave)
                );
              store.commit("users/setStaffId", null);
            }
            store.commit("group/setNewStaffForGroupValue", null);
          }
        });
      }
    });

    const addPrevInfo = () => {
      setTimeout(() => {
        const selectUsers = $("#usersSelect");
        const selectCurator = $("#curatorSelect");
        const newCuratorOption = new Option(
          group.value.owner.name,
          group.value.owner.id,
          true,
          true
        );
        selectCurator.append(newCuratorOption).trigger("change");
        selectCurator.trigger({
          type: "select2:select",
          params: {
            data: group.value.owner,
          },
        });
        group.value.users.forEach((element) => {
          const newOption = new Option(element.name, element.id, true, true);
          selectUsers.append(newOption).trigger("change");
          selectUsers.trigger({
            type: "select2:select",
            params: {
              data: element,
            },
          });
        });
      }, 0);
    };

    const newChildGroup = () => {
      isPreloaderSave.value = true;
      store
        .dispatch("group/createGroup", {
          ...childGroupForm,
          owner_id: group.value.owner_id,
          parent_id: group.value.id,
        })
        .then(() => {
          childGroupForm.name = null;
          childGroupForm.users = null;
          isPreloaderSave.value = false;
        })
        .catch(standartError(setErrors, clearCustomErrors, isPreloaderSave));
    };
    const createNewStaff = () => {
      store.commit("group/setNewStaffForGroup", true);
      router.push("/staff/edit");
    };
    const submitGroup = () => {
      isPreloaderSave.value = true;
      if (route.params.id) {
        store
          .dispatch("group/updateGroup", {
            id: route.params.id,
            group: {
              ...form,
              group_id: route.params.id,
              owner_id: form.owner_id
                ? Number(form.owner_id)
                : Number(profile.value.id),
              users: form.users ? form.users.map((user) => Number(user)) : [],
              company_id: profile.value.company_id,
            },
          })
          .catch(standartError(setErrors, clearCustomErrors));
        store
          .dispatch("group/updateGroupUsers", {
            id: route.params.id,
            users: form.users ? form.users.map((user) => Number(user)) : [],
          })
          .then(() => (isPreloaderSave.value = false))
          .catch(standartError(setErrors, clearCustomErrors, isPreloaderSave));
      } else {
        store
          .dispatch("group/createGroup", {
            ...form,
            owner_id: form.owner_id
              ? Number(form.owner_id)
              : Number(profile.value.id),
          })
          .then(() => {
            store
              .dispatch("group/updateGroupUsers", {
                id: group.value.id,
                users: form.users ? form.users.map((user) => Number(user)) : [],
              })
              .then(() => router.push("/group"));
          })
          .then(() => (isPreloaderSave.value = false))
          .catch(standartError(setErrors, clearCustomErrors, isPreloaderSave));
      }
    };

    watch(
      () => route.params.id,
      () => {
        if (route.params.id) {
          isPreloaderLoad.value = true;
          store.dispatch("group/getGroup", route.params.id).then(() => {
            form.name = group.value.name;
            form.parent = group.value.parent;
            form.users = group.value.users.map((user) => user.id);
            childUsersOptions.value = group.value.users.map((user) => {
              return { id: user.id, text: user.name };
            });
            form.owner_id = String(group.value.owner.id);
            isPreloaderLoad.value = false;
          });
        }
      }
    );

    return {
      form,
      submitGroup,
      newChild,
      group,
      router,
      staffs,
      childs,
      route,
      childUsersOptions,
      childGroupForm,
      profile,
      isPreloaderLoad,
      isPreloaderSave,
      newChildGroup,
      createNewStaff,
      usersAjax,
      curatorsData
    };
  },
};
</script>

<style lang="scss" scoped>
.group-form {
  padding: 0 0 10px 0;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  .form-group {
    margin: 35px -15px;
  }

  &__organization {
    flex: 0 1 20%;
    padding: 20px 40px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__group {
    flex: 1 0 80%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &__group,
  &__organization {
    margin-bottom: 0;
  }

  &__title {
    display: block;
    font-size: 26px;
    padding-bottom: 10px;
  }

  &__text {
    font-size: 27px;
    display: block;
    max-width: 90%;
  }

  &__col1 {
    flex: 1;
  }

  &__children-area {
    margin-top: 60px;
  }

  @media (max-width: 1200px) {
    &__inputs {
      flex-direction: column;
    }
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    .form-group {
      margin: 15px -15px;
    }
  }

  @media (max-width: 425px) {
    &__organization {
      padding: 20px 20px 0;
    }

    .form-group {
      margin: 15px -15px;
    }
  }
}

.children-area {
  p {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 15px;
  }

  // .children-area__header

  &__header {
    background-color: var(--main-color);
    width: 100%;
    padding: 10px 0;
  }

  .form-group {
    margin: 15px -5px 0 0;
    justify-content: start;
  }

  &__input {
    width: 60%;
    padding-bottom: 25px;

    label {
      color: white;
      display: block;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .btn {
    width: 120px;
    margin: 15px 0 29px 0;
  }

  // .children-area__list

  &__list {
    border: 1px solid var(--cream-color);
    height: 200px;
    overflow-y: auto;
  }

  // .children-area__item

  &__item {
    padding: 9px 35px;
    background-color: #fff;
    display: flex;
    border-bottom: 1px solid var(--cream-color);
    align-items: center;

    a {
      display: block;
      color: var(--common-text-color);
      width: 100%;
      height: 100%;
      word-break: break-word;
    }

    button {
      background-color: transparent;
      padding: 6px 12px;
      font-size: 18px;
    }

    &:nth-of-type(odd) {
      background-color: #f5f5f5;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  @media (max-width: 1144px) {
    .btn {
      margin-bottom: 10px;
      margin-top: 0;
    }

    &__header {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    &__input {
      width: 80%;
    }
  }

  @media (max-width: 973px) {
    width: 100%;
    margin-top: 30px;

    .form-group {
      margin: 15px -15px 0 -15px;
    }
  }

  @media (max-width: 685px) {
    &__header {
      flex-direction: column;
      align-items: center;
    }

    &__input {
      width: 80%;
    }

    .btn {
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }
}
</style>